<template>
  <div id="highlights-list">
    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-select
            v-model="typeFilter"
            :label="t('highlights.type')"
            :placeholder="t('highlights.type')"
            :items="typesOptions"
            item-text="text"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
            @change="updateFilter('views-highlight-list', 'type', typeFilter)"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider />

      <v-row
        justify="end"
        class="px-2 ma-0"
      >
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(56)"
          cols="12"
          md="2"
          sm="12"
          class="d-flex flex-row"
        >
          <v-btn
            class="white--text"
            color="btnPrimary"
            block
            @click="exportExcel('/highlight/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        class="text-no-wrap"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
        :item-class="() => 'cursor-pointer'"
        @click:row="onShow"
      >
        <template #[`item.facility_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="resolveClientAvatarVariant(item.status)"
              :class="`v-avatar-light-bg ${resolveClientAvatarVariant(item.status)}--text`"
              size="30"
            >
              <v-img
                v-if="item.facility_logo"
                :src="item.facility_logo"
                eager
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.facility_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.facility_name }}</span>
              <span class="text-xs">{{ item.field_name }}</span>
            </div>
          </div>
        </template>

        <template #[`item.video_date`]="{ item }">
          {{ formatStdDate(item.video_date, 'datelist', configObjFacility.is12Hour, $i18n.locale) }}
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(79)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>{{ t('tooltip.show') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(79)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="secondary"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShowVideo(item)"
              >
                {{ icons.mdiVideoOutline }}
              </v-icon>
            </template>
            <span>{{ t('tooltip.play_video') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(80)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDownload(item)"
              >
                {{ icons.mdiDownload }}
              </v-icon>
            </template>
            <span>{{ t('tooltip.download') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="isOpenModal"
      max-width="90%"
    >
      <highlight-video-tab
        :data-params="computedDataVideo"
        :option="2"
        :tab="isOpenModal ? 1 : 0"
      />
    </v-dialog>

    <v-navigation-drawer
      v-model="isViewDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <container-drawer
        v-model="isViewDrawerActive"
        :is-open="isViewDrawerActive"
        :option="option"
        :label="t('highlights.highlight')"
      >
        <template v-slot:form="{ attrs }">
          <highlight-form
            v-bind="attrs"
            :is-open="isViewDrawerActive"
            :option="option"
            :data-id="dataId"
            @refresh="$emit('refresh')"
            @open-drawer="(val) => $emit('open-drawer', val)"
            @update-option="(val) => $emit('update-option', val)"
          />
        </template>
      </container-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiEyeOutline,
  mdiVideoOutline,
  mdiDownload,
} from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import { useRouter, formatStdDate } from '@core/utils'
import { validatePassword } from '@api'
import Swal from 'sweetalert2'
import exportExcel from '@core/utils/useExportExcel'
import { error } from '@core/utils/toasted'
import { avatarText } from '@core/utils/filter'
import useSelectOptions from '@core/utils/useSelectOptions'
import ContainerDrawer from '../components/container-drawer/ContainerDrawer.vue'
import HighlightForm from './HighlightForm.vue'

import useHighlightsList from './useHighlightsList'

import HighlightVideoTab from './HighlightVideoTab.vue'

export default {
  components: {
    HighlightVideoTab,
    ContainerDrawer,
    HighlightForm,
  },
  setup() {
    const { router, route } = useRouter()
    const { configObjFacility } = useSelectOptions()

    const option = ref(0)
    const dataId = ref(0)
    const isOpenModal = ref(false)
    const isViewDrawerActive = ref(false)
    const dataVideo = ref()
    const computedDataVideo = computed(() => dataVideo.value)

    const {
      listTable,
      computedTableColumns,
      totalListTable,
      loading,
      filters,
      options,
      descSort,
      searchQuery,
      typeFilter,
      typesOptions,

      t,
      resolveClientAvatarVariant,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
      getDataBySearch,
    } = useHighlightsList()

    const allowExportExcel = () => {
      Swal.fire({
        title: 'Ingresar contraseña',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: async pass => {
          console.log('preConfirm')

          return validatePassword(pass)
            .then(response => {
              if (response.ok === 0) {
                error(response.message.text)
              }

              return response
            })
            .catch(e => {
              console.error('e', e)
            })
        },
      }).then(async result => {
        if (result.value && result.value.ok) {
          Swal.close()
        } else {
          router.push({
            name: 'views-order-video-list',
          })
        }
      })
    }

    const onDownload = item => {
      const link = document.createElement('a')
      link.href = item.link
      link.type = 'video/mp4'
      link.download = 'Highlight.mp4'
      link.click()
      link.remove()
    }

    const onShow = item => {
      option.value = 2
      dataId.value = item.id

      isViewDrawerActive.value = true

      // router.push({
      //   name: 'views-highlight-form',
      //   params: {
      //     id: item.id,
      //     dataParams: item,
      //     option: 2,
      //     pageParams: options,
      //     filterParams: filters,
      //   },
      // })
    }

    const onShowVideo = item => {
      dataVideo.value = item
      isOpenModal.value = true
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      if (pageParams) setOptions(pageParams.value)
      if (filterParams) setFilters(filterParams.value)

      const filtersStored = getFilterByModule('views-highlight-list')
      if (filtersStored.type) typeFilter.value = filtersStored.type
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await getDataBySearch(100)
    })

    return {
      option,
      dataId,
      listTable,
      computedTableColumns,
      searchQuery,
      typeFilter,
      totalListTable,
      loading,
      filters,
      options,
      descSort,

      typesOptions,
      isOpenModal,
      isViewDrawerActive,
      dataVideo,
      computedDataVideo,
      configObjFacility,

      exportExcel,
      allowExportExcel,
      hasPermission,
      resolveClientAvatarVariant,
      avatarText,

      onShow,
      onShowVideo,
      onDownload,
      setFilters,
      setOptions,
      updateFilter,
      formatStdDate,
      getDataBySearch,

      // i18n
      t,

      // icons
      icons: {
        mdiEyeOutline,
        mdiVideoOutline,
        mdiDownload,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.export-excel {
  display: block;
}
</style>
