<template>
  <div id="sport-list">
    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-select
            v-model="fieldFilter"
            :label="t('fields.field')"
            :placeholder="t('fields.field')"
            :items="fieldsOptions"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
            @change="updateFilter('views-recurring-order-list', 'field', fieldFilter)"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(78) && false"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        item-key="id"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
        :item-class="() => 'cursor-pointer'"
        @click:row="onEdit"
      >
        <template #[`item.facility_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.facility_logo ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.facility_logo"
                :src="item.facility_logo"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.facility_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              {{ item.facility_name }}
            </div>
          </div>
        </template>

        <template #[`item.start_time`]="{item}">
          {{ formatStdDate(item.start_time, 'time', configObjFacility.is12Hour, $i18n.locale) }}
        </template>

        <template #[`item.video_time`]="{item}">
          {{ item.video_time }} Mins
        </template>

        <template #[`item.weekdays`]="{item}">
          {{ resolveWeekdaysText(item.weekdays) }}
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveSportStatusVariant(item.status)"
            :class="`${resolveSportStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveSportStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(72)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.detail') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(73)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.edit') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(74)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-navigation-drawer
      v-model="isViewDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <container-drawer
        v-model="isViewDrawerActive"
        :is-open="isViewDrawerActive"
        :option="option"
        :label="t('Recurring Orders')"
      >
        <template v-slot:form="{ attrs }">
          <recurring-order-form
            v-bind="attrs"
            :is-open="isViewDrawerActive"
            :option="option"
            :data-id="dataId"
            @refresh="() => {
              isViewDrawerActive = false
              getDataBySearch(100)
            }"
            @open-drawer="(val) => $emit('open-drawer', val)"
            @update-option="(val) => $emit('update-option', val)"
          />
        </template>
      </container-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onMounted } from '@vue/composition-api'
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatStdDate } from '@core/utils'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import { deleteRecurringOrder } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'
import ContainerDrawer from '../components/container-drawer/ContainerDrawer.vue'
import RecurringOrderForm from './RecurringOrderForm.vue'

import useRecurringOrderList from './useRecurringOrderList'

export default {
  components: {
    ContainerDrawer,
    RecurringOrderForm,
  },
  setup() {
    const { t } = useUtils()
    const { route } = useRouter()
    const { configObjFacility } = useSelectOptions()

    const {
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      loading,
      filters,
      options,
      descSort,
      userData,
      fieldFilter,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,
      configFacility,

      fetchPagedRecurringOrders,
      resolveSportStatusVariant,
      resolveSportStatusText,
      resolveWeekdaysText,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
      getDataBySearch,
      fetchFields,
    } = useRecurringOrderList()

    const isViewDrawerActive = ref(false)
    const option = ref(0)
    const dataId = ref(null)
    const dataParams = ref(null)

    const onCreate = () => {
      option.value = 1
      dataId.value = null
      isViewDrawerActive.value = true

      // router.push({
      //   name: 'views-recurring-order-form',
      //   params: {
      //     option: 1,
      //   },
      // })
    }

    const onEdit = item => {
      option.value = 3
      dataId.value = item.id
      dataParams.value = item
      isViewDrawerActive.value = true

      // router.push({
      //   name: 'views-recurring-order-form',
      //   params: {
      //     id: item.id,
      //     dataParams: item,
      //     option: 3,
      //     pageParams: options.value,
      //     filterParams: filters.value,
      //   },
      // })
    }

    const onShow = item => {
      option.value = 2
      dataId.value = item.id
      dataParams.value = item
      isViewDrawerActive.value = true

      // router.push({
      //   name: 'views-recurring-order-form',
      //   params: {
      //     id: item.id,
      //     dataParams: item,
      //     option: 2,
      //     pageParams: options.value,
      //     filterParams: filters.value,
      //   },
      // })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteRecurringOrder(id)
          if (response.ok) {
            success(response.message)
            fetchPagedRecurringOrders()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-recurring-order-list')
      if (filtersStored.field) fieldFilter.value = filtersStored.field
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await fetchFields(configFacility.value)
      await getDataBySearch(100)
    })

    return {
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      loading,
      filters,
      options,
      descSort,
      userData,
      fieldFilter,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,
      configObjFacility,
      isViewDrawerActive,
      dataId,
      dataParams,
      option,

      onCreate,
      onEdit,
      onShow,
      onDelete,
      avatarText,
      resolveSportStatusVariant,
      resolveSportStatusText,
      resolveWeekdaysText,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      formatStdDate,
      getDataBySearch,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
