<template>
  <div id="video-list">
    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('select.status')"
            @change="updateFilter('views-video-list', 'status', statusFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
          class="pb-0"
        >
          <v-menu
            v-model="modalFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                :placeholder="t('transactions.from_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              color="primary"
              :max="dateTo ? dateTo : maxDate"
              @input="modalFrom = false"
              @change="updateFilter('views-video-list', 'dateFrom', dateFrom)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="2"
          class="pb-0"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                :placeholder="t('transactions.to_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              color="primary"
              :max="maxDate"
              :min="dateFrom"
              @input="modalTo = false"
              @change="updateFilter('views-video-list', 'dateTo', dateTo)"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider />

      <v-row
        class="px-2 ma-0"
        justify="end"
      >
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('search')"
          ></v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="userData && userData.role === 'A' && hasPermission(17)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="exportExcel('/video/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        :loading="loading"
        class="text-no-wrap"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
        :item-class="() => 'cursor-pointer'"
        @click:row="onEdit"
      >
        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :style="$vuetify.theme.dark ? 'color: #e7e3fcad' : '#3b0000de' "
            :to="{ name: 'views-video-preview', params: { id: item.id } }"
          >
            #{{ item.id }}
          </router-link>
        </template>

        <template #[`item.facility_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="resolveClientAvatarVariant(item.status)"
              :class="`v-avatar-light-bg ${resolveClientAvatarVariant(item.status)}--text`"
              size="30"
            >
              <v-img
                v-if="item.facility_avatar"
                :src="item.facility_avatar"
                eager
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.facility_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.facility_name }}</span>
              <span class="text-xs">{{ item.field_name }}</span>
            </div>
          </div>
        </template>

        <template #[`item.video_date`]="{item}">
          {{ formatStdDate(item.video_date, 'datelist', configObjFacility.is12Hour, $i18n.locale) }}
        </template>

        <template #[`item.video_time`]="{item}">
          <span class="text-no-wrap">{{ item.video_time / 60 }} Mins</span>
        </template>

        <template #[`item.start_time`]="{item}">
          {{ formatStdDate(item.start_time, 'time', configObjFacility.is12Hour, $i18n.locale) }}
        </template>

        <template #[`item.amount`]="{item}">
          <span class="text-no-wrap">{{ formatCurrency(item.amount) }}</span>
        </template>

        <template #[`item.status_str`]="{item}">
          <v-chip
            small
            :color="resolveClientAvatarVariant(item.status)"
            :class="`${resolveClientAvatarVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status_str }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-tooltip
              v-if="hasPermission(16)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="info"
                  v-bind="attrs"
                  :to="{ name: 'views-video-preview', params: { id: item.id, dataParams: item, option: 2, pageParams: options, filterParams: filters } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View Video</span>
            </v-tooltip>

            <v-tooltip
              v-if="item.status === 'A'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  :class="{'cursor-default': after30Days(item.video_date)}"
                  :color="after30Days(item.video_date) ? 'primary' : 'secondary'"
                  v-bind="attrs"
                  v-on="on"
                  @click="after30Days(item.video_date) ? null : downloadVideo(item.link)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="after30Days(item.video_date)">{{ t('videos.video_not_available') }}</span>
              <span v-else>Download Video</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-navigation-drawer
      v-model="isViewDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <container-drawer
        v-model="isViewDrawerActive"
        :is-open="isViewDrawerActive"
        :option="option"
        :label="t('Recording')"
      >
        <template v-slot:form="{ attrs }">
          <video-preview
            v-bind="attrs"
            :is-open="isViewDrawerActive"
            :option="option"
            :data-id="dataId"
            @refresh="() => {
              isViewDrawerActive = false
            }"
            @open-drawer="(val) => $emit('open-drawer', val)"
            @update-option="(val) => $emit('update-option', val)"
          />
        </template>
      </container-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */

import { mdiEyeOutline, mdiDownload } from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  formatCurrency, useRouter, downloadVideo, after30Days, formatStdDate,
} from '@core/utils'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import exportExcel from '@core/utils/useExportExcel'

import VideoPreview from '@/views/videos/video-preview/VideoPreview.vue'
import ContainerDrawer from '../../components/container-drawer/ContainerDrawer.vue'

import useVideoList from './useVideoList'

export default {
  components: {
    ContainerDrawer,
    VideoPreview,
  },
  setup() {
    const { route } = useRouter()
    const { configObjFacility } = useSelectOptions()

    const {
      listTable,
      computedTableColumns,
      totalListTable,
      descSort,
      loading,
      options,
      filters,
      searchQuery,
      statusFilter,
      maxDate,
      dateFrom,
      dateTo,
      userData,

      t,
      resolveClientAvatarVariant,
      getDataBySearch,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
    } = useVideoList()

    const modalFrom = ref(false)
    const modalTo = ref(false)

    const isViewDrawerActive = ref(false)
    const option = ref(0)
    const dataId = ref(null)
    const dataParams = ref(null)

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
      { text: t('status.processing'), value: 'P' },
      { text: t('status.recording'), value: 'R' },
      { text: t('status.scheduled'), value: 'S' },
      { text: t('status.cancelled'), value: 'C' },
    ])

    const onEdit = item => {
      option.value = 3
      dataId.value = item.id
      dataParams.value = item
      isViewDrawerActive.value = true
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-video-list')
      if (filtersStored.status) statusFilter.value = filtersStored.status
      if (filtersStored.dateFrom) dateFrom.value = filtersStored.dateFrom
      if (filtersStored.dateTo) dateTo.value = filtersStored.dateTo
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await getDataBySearch(500)
    })

    return {
      listTable,
      computedTableColumns,
      totalListTable,
      descSort,
      loading,
      options,
      filters,
      searchQuery,
      statusFilter,
      maxDate,
      dateFrom,
      dateTo,

      isViewDrawerActive,
      option,
      dataId,
      dataParams,

      statusOptions,
      userData,
      modalFrom,
      modalTo,
      configObjFacility,

      // i18n
      t,

      resolveClientAvatarVariant,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,

      avatarText,
      formatCurrency,
      exportExcel,
      downloadVideo,
      after30Days,
      formatStdDate,
      onEdit,

      icons: {
        mdiEyeOutline,
        mdiDownload,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';
#video-list {
  .video-list-actions {
    max-width: 7.81rem;
  }
  .video-list-search {
    max-width: 10.625rem;
  }
  .video-list-status {
    max-width: 11.3rem;
  }
}
</style>
